import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { ProductsDifferencesProps } from '@shared/types/umbraco/compositions';

import './ProductsDifferences.scss';
import ProductsDifferences from './ProductsDifferences';

const ProductsDifferencesWrapper: FC<ProductsDifferencesProps.IProductsDifferencesProps> = ({
  productsDifferencesData: {
    differences: {
      productsDifferencesTitle,
      productsDifferencesBackground,
      bigSizeTitle,
      isFullWidthLink,
      borderedImage,
      transparentTitles,
      productsDifferencesTables,
      productsDifferencesLink,
      packshotSize,
    },
  },
}) => (
  <ProductsDifferences
    productsDifferencesTitle={productsDifferencesTitle}
    productsDifferencesBackground={productsDifferencesBackground}
    bigSizeTitle={bigSizeTitle}
    isFullWidthLink={isFullWidthLink}
    borderedImage={borderedImage}
    transparentTitles={transparentTitles}
    productsDifferencesTables={productsDifferencesTables}
    productsDifferencesLink={productsDifferencesLink}
    packshotSize={packshotSize}
  />
);

export const query = graphql`
  fragment FragmentProductsDifferencesWrapper on TProductsDifferences {
    structure
    properties {
      productsDifferencesData {
        differences {
          ... on TProductsDifferencesProperties {
            ...FragmentProductsDifferencesProperties
          }
        }
      }
    }
  }
`;

export default ProductsDifferencesWrapper;
